import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>For more Educational Hindu Content Visit <a href="https://www.hinduismtoday.com/">www.hinduismtoday.com</a></h2>
      <p>
        Materials provided by Himalayan Academy and Hinduism Today Magazine
      </p>
      
    </section>
    <section>
      <h2>Contact</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>107 Kaholalele Road &bull; Kapaa, HI 96746 &bull; USA</dd>
        <dt>Phone</dt>
        <dd>1-808-822-3012</dd>
        <dt>Email</dt>
        <dd>
          <a href="https://www.himalayanacademy.com/info/contact">Contact Information</a>
        </dd>
      </dl>
     
    </section>
    <p className="copyright">
      &copy; Himalayan Academy Publications.
    </p>
  </footer>
)

export default Footer
