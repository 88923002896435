import React from 'react'

import logo from '../assets/images/ganesha04.png';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <h1>Pancha Ganapati</h1>
        <h3>Lord Ganesha's Annual Holiday Celebration!!!</h3>
        <h3>December 21-25th</h3>
    </header>
)

export default Header
