import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/001_gan.jpg'
import pic02 from '../assets/images/185_gan.jpg'
import pic03 from '../assets/images/183_gan.jpg'
import pic04 from '../assets/images/002_gan.jpg'
import pic05 from '../assets/images/184_gan.jpg'
import pic06 from '../assets/images/186_gan.jpg'
import pic07 from '../assets/images/006_gan.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Pancha Ganapti" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>A Family Festival of Giving</h2>
                </header>
                <p>
                For those unfamiliar with this holiday, you can think of Pancha Ganapati as the Hindu Christmas, a modern winter holiday full of family-centered happenings, but with five days of gifts for the kids, not just one. From December 21 to 25, Hindus worship Lord Ganesha, the elephant-headed Lord of culture and new beginnings. Family members work to mend past mistakes and bring His blessings of joy and harmony into five realms of their life, a wider circle each day: family, friends, associates, culture and religion.
                </p>
                <p>
                Pancha Ganapati is a modern Hindu festival honoring the Five-Faced (pancha means “five”) Maha Ganapati—Lord of Categories. It falls during the 30 days of the ancient Markali Pillaiyar home festival and lasts for five days—from December 21 through 25.                </p>
                
              </div>
              <span className="image">
                <img src={pic01} alt="" />
                
              </span>
              
            </div>
          </section>


         
          <section id=" " className="main special">
                       
          <h3>What Happens on Each of the Five Days?</h3>
                <p>
                Each day, using lights, festoons and cloth, the shrine is decorated in the color of the day. Before the puja, a tray of sweets, fruits and incense is prepared and offered to Lord Ganapati, ideally by the children. Chants and songs are sung in His praise. After the worship, diverse sweets are shared by one and all. Each day colorfully wrapped gifts are given to the children, who place them before Pancha Ganapati to open on the fifth day. The adults receive gifts, too! Some families keep a five-faced murti just for this festival and on each day worship one of the five faces of Pancha Ganapati.</p>
                        
                     </section>

          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>What Is the Nature of this Celebration?</h2>
                </header>
                 
                <p>
                Pancha Ganapati includes outings, picnics, feasts and exchange of cards and gifts with relatives, friends and business associates. A special temporary shrine is created in the main living room or the shrine room and festively decorated. At the center is placed a large wooden or bronze statue of Lord Panchamukha (“five-faced”) Ganapati, a form of Ganesha. Any large picture or statue of Ganesha will also do. Each morning the children decorate and dress Him in the color of that day, representing one of His five rays of energy, or shaktis.</p>
                                              
               
                 
                 
              </div>
              <span className="image">
                <img src={pic07} alt="" />
                
              </span>
              
            </div>
          </section>

          <section id=" " className="main special">
                       
                       <p>
                           Since most Hindus do not celebrate Christmas, they may find it difficult to relate in a meaningful way to those who do. Their children are often embarrassed when asked why they do not receive gifts on December 25. Adults feel the need to give gifts and mail greeting cards as well as receive them from their relatives, neighbors, friends and business associates. Pancha Ganapati is a Hindu expression of this natural season of worship, gift-giving and celebration.                </p>
                           <p>
                           December 25 and the days that precede and follow it have become a special time of year for people of many religions, and for the non-religious as well. In fact, this season has become so universally observed that it is popularly considered a secular, cultural holiday in addition to its special observance by certain religions. Recognizing this fact, the US Supreme Court ruled that Christmas is a secular, social holiday. This is because it has become a time for everyone to rejoice, give and share their abundance, each in their own way. Pancha Ganapati is celebrated as a contemporary home observance.</p>
                        
                     </section>


{/* ===== DAY 1 ===== */}

          <section id="first" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Festival Day 1<br/>December 21, Yellow</h2>
                  <h3>Nurturing Harmony Among Family Members</h3>
                </header>
                <p>
                The family sadhana for the first day of Pancha Ganapati is to create a vibration of love and harmony among immediate family members. The day begins early, with the entire family working together to design and decorate the shrine with traditional symbols, rangoli, lamps and more. A grand puja is performed invoking the spirit of Pancha Ganapati in the home. The sadhana of the day begins with the family sitting together for the purpose of easing any strained relationships that have arisen during the year. They make amends one with another for misdeeds performed, insults given, mental pain and injuries caused and suffered. Once forgiveness is offered to all by one and all, they speak of one another’s good qualities and resolve that in the days ahead they will remember the futility of trying to change others and the practicality of changing oneself to be the silent example for all to witness. Gifts are then exchanged and placed unopened before Pancha Ganapati. Family harmony is important to all Hindus, and this sadhana is taken most seriously. Done well, it can heal the past and brighten the family’s future.
                </p>
                
               
               
                 
              
              </div>
              <span className="image">
                <img src={pic02} alt="" />
              </span>
              
            </div>
          </section>
      {/* ===== DAY 2 ===== */}

          <section id="second" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Festival Day 2<br/>December 22, Blue</h2>
                  <h3>Nurturing Harmony Among Friends & Neighbors</h3>
                </header>
                <p>
                The family sadhana for the second day of Pancha Ganapati is to create a vibration of love and harmony among neighbors, relatives and close friends and present them with heartfelt gifts. Members of the family offer apologies and clear up any misunderstandings that exist beyond the home, among friends and neighbors. Relatives and friends in far-off places are written to or called, forgiveness is sought, amends made and tensions released. Gifts received are placed unopened before Pancha Ganapati.
                </p>
                 
               
               
                 
                
              </div>
              <span className="image">
                <img src={pic03} alt="" />
              </span>
              
            </div>
          </section>
{/* ===== DAY 3 ===== */}

<section id="third" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Festival Day 3<br/>December 23, Red</h2>
                  <h3>Nurturing Harmony Among Associates and the Public</h3>
                </header>
                <p>
                The family sadhana for the third day is to create a vibration of love and harmony among business associates, the casual merchant and the public at large. This is the day for presenting gifts to merchants and customers and honoring employers and employees with gifts and appreciation. An important effort on this day is the settling of all debts and disputes. Gifts received are placed unopened before the Deity.
                </p>
                                  
                
              </div>
              <span className="image">
                <img src={pic04} alt="" />
              </span>
              
            </div>
          </section>

{/* ===== DAY 4 ===== */}

<section id="fourth" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Festival Day 4<br/>December 24, Green</h2>
                  <h3>Nurturing Harmony through Cultural Arts</h3>
                </header>
                <p>
                The sadhana of day four is to draw forth the vibration of joy and harmony that comes from music, art, drama and the dance. Family, relatives and friends gather for satsanga to share and enjoy their artistic gifts. Children dance and sing. Poems are recited. Plays are presented. When the program is over, all sit together before Pancha Ganapati, Patron of the Arts and Guardian of Culture, discussing Hindu Dharma and making plans to bring more cultural refinements into each household. More gifts are placed before Lord Ganapati.
                </p>
                                  
               
              </div>
              <span className="image">
                <img src={pic05} alt="" />
              </span>
              
            </div>
          </section>


{/* ===== DAY 5 ===== */}

<section id="fifth" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Festival Day 5<br/>December 25, Orange</h2>
                  <h3>Nurturing Harmony among All Three Worlds</h3>
                </header>
                <p>
                The family sadhana for the final day of Pancha Ganapati is to bring forth love and harmony within all three worlds. Because of sadhanas well performed during the first four days, the family is now more open and aware of Ganesa’s grace, and their love for Him is overflowing. On this day the entire family experiences an outpouring of affection and tranquility from the great God Himself. His blessings fill the home and the hearts of everyone within it, inspiring them anew for the coming year. This exchange of affection between all members of the family and the Lord is invoked and perpetuated through the day by performing five special pujas. These five pujas to Pancha Ganapati (see sidebar below) solicit help from His devas in the home and establish the patterns for improvement in family life. The overflowing love that is felt today will inspire generosity in the year to come, bringing abundance and good fortune in return. All gifts received during the day are placed unopened before Pancha Ganapati.
                </p> 
               
                                  
               
              </div>
              <span className="image">
                <img src={pic06} alt="" />
              </span>
              
            </div>
          </section>

          <section id=" " className="main special">
                       
                       <h3>The Final Day's Five Pujas and Opening of Gifts</h3>
                       <p>
                The first puja is at 6am. Before the puja, personal offering trays are prepared and placed before His shrine. After the puja, each one gives verbal testimony about prayers that were answered during the past year. Hearing testimonies strengthens the faith of everyone. Then vows of sacrifice can be verbally made. Vows should improve the quality of the life of the individual, such as giving up smoking or swearing or correcting other harmful habits. The second puja is at 9am, and the third at 12 noon. The fourth puja is held at 3pm. The fifth and final puja at 6pm is the long-awaited time. The five sadhanas have been completed. Peace, love and harmony among everyone has been restored. After the puja and before the great feast that follows, Lord Panchamukha Ganapati Himself gives His final darshana and prasada to one and all. Gifts are distributed and joyously opened. Happy children. Happy parents. Happy God.
                </p>
                                     
                                  </section>



          

          <section id="second" className="main special">
            <header className="major">
              <h2>Harmony among All Three Worlds</h2>
              <h3>A Concluding Prayer</h3>
            </header>
             
            <p>Recite these short prayers after the final puja on the fifth day of Pancha Ganapati. May His blessings flood your home with bounteous joy. May you and your family experience His boons of abundance, dharma, culture and sensitivity to the feelings of others that He will grant in the months and years ahead. </p>
                <p>
                <i>Om bhur bhuvah suvah<br/>Ekadantaya vidmahe</i><br/><br/>O divine beings of all three worlds, let us bring our minds to rest in the darshan of Him who has one tusk.<br/><br/><i>Vakratundaya dhimahi</i><br/><br/>Let us meditate upon Him who has the form of an elephant with a curved trunk.<br/><br/><i>Tanno dantih prachodayat</i><br/><br/>May He guide us always along the right path.<br/><br/><i>Jai Ganesha!<br/>Jai Panchamukha Ganapati!<br/>Jai Ganesha! Jai! Jai!<br/>Aum shanti, shanti, shantih, Aum</i><br/><br/>Victory to the Lord of Celestials!<br/>Hail to the Five-Faced Lord of ganas!<br/>Victory to the Lord of Celestials!<br/>Hail to Ganesha. Jai, jai.<br/>Aum, peace, peace, peace, Aum.</p>
            <footer className="major">
               
            </footer>
          </section>

          
        </div>
      </Layout>
    )
  }
}

export default Index
